var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isLoading
      ? _c("div", [
          (_vm.document || _vm.documentFromId) &&
          (_vm.canViewDocument || _vm.isStaff)
            ? _c(
                "section",
                { class: [_vm.isFullScreen ? "container-is-fs" : ""] },
                [
                  _c(
                    "div",
                    {
                      staticClass: "has-text-centered -heading",
                      staticStyle: { "background-color": "#ededed" },
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: " is-size-6",
                          staticStyle: { padding: "10px 0px" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.document
                                  ? _vm.document.displayName.toLocaleUpperCase()
                                  : _vm.documentFromId
                                  ? _vm.documentFromId.displayName.toLocaleUpperCase()
                                  : null
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "pdf-controls" }, [
                        _c(
                          "button",
                          {
                            staticClass: "download",
                            attrs: {
                              title: _vm.isFullScreen
                                ? "Close Fullscreen"
                                : "Open Fullscreen",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isFullScreen = !_vm.isFullScreen
                              },
                            },
                          },
                          [
                            !_vm.isFullScreen
                              ? _c("i", { staticClass: "fa fa-expand" })
                              : _c("i", { staticClass: "fas fa-compress" }),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "print",
                            attrs: {
                              disabled: _vm.isPrinting,
                              title: "Print PDF",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.print()
                              },
                            },
                          },
                          [
                            _vm.isPrinting
                              ? _c("spin-loader")
                              : _c("i", { class: ["fa", "fa-print"] }),
                          ],
                          1
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "download",
                            attrs: {
                              href: _vm.document.path,
                              download: "",
                              target: "_blank",
                              title: "Download PDF",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.isDownloading = true
                                _vm.api().C8925Reports.downloadReport(
                                  {
                                    batchId: 0,
                                    reportIds: [_vm.$route.params.reportId],
                                    siteId: _vm.activeSite.id,
                                  },
                                  function () {
                                    return (_vm.isDownloading = false)
                                  }
                                )
                              },
                            },
                          },
                          [
                            !_vm.isDownloading
                              ? _c("i", { staticClass: "fa fa-download" })
                              : _c("spin-loader"),
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "download",
                            attrs: { title: "Zoom In" },
                            on: {
                              click: function ($event) {
                                _vm.zoomLevel <= 150
                                  ? (_vm.zoomLevel += 25)
                                  : (_vm.zoomLevel = 200)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-search-plus" })]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "download",
                            attrs: { title: "Zoom Out" },
                            on: {
                              click: function ($event) {
                                _vm.zoomLevel > 50
                                  ? (_vm.zoomLevel -= 25)
                                  : (_vm.zoomLevel = 50)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-search-minus" })]
                        ),
                        _vm._v(" " + _vm._s(_vm.zoomLevel) + "% "),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { width: "90%" },
                      attrs: { id: "body-content-area-all", pdfkit: "" },
                    },
                    [
                      _vm.document || _vm.documentFromId
                        ? _c("div", { staticClass: "pdf-page" }, [
                            _vm.isIE && !_vm.tryRenderingIE
                              ? _c("div", { attrs: { id: "pagelayout" } }, [
                                  _c("p", [
                                    _vm._v(
                                      "It seems your browser does not support PDF previewing. "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.document.path,
                                          download: "",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("Download the report here")]
                                    ),
                                    _vm._v("."),
                                  ]),
                                ])
                              : _vm._e(),
                            (_vm.document &&
                              _vm.document.extension.toLowerCase() !==
                                ".pdf") ||
                            (_vm.documentFromId &&
                              _vm.documentFromId.extension.toLowerCase() !==
                                ".pdf")
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      height: "100%!important",
                                      "scrollbar-width": "thin!important",
                                    },
                                    attrs: { id: "pagelayout" },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "It seems there is no preview for this type of document available. "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.document.path,
                                            download: "",
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v("Download the report here")]
                                      ),
                                      _vm._v("."),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  { attrs: { id: "pagelayout" } },
                                  [
                                    _vm.numPages
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "pdf-container",
                                            attrs: { zoom: _vm.zoomLevel },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "container-to-the-report-container",
                                              },
                                              _vm._l(
                                                _vm.numPages,
                                                function (i) {
                                                  return _c(
                                                    "pdf",
                                                    {
                                                      key: i,
                                                      ref: "pdf",
                                                      refInFor: true,
                                                      staticClass: "pdf",
                                                      attrs: {
                                                        src: _vm.document.path,
                                                        page: i,
                                                      },
                                                      on: {
                                                        progress: function (
                                                          $event
                                                        ) {
                                                          return _vm.progress(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Loading Report Preview... "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        )
                                      : _c("Loading"),
                                  ],
                                  1
                                ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _c("div", { staticClass: "center-it" }, [
          _c(
            "div",
            [
              _c("center", [
                _c("h1", { staticClass: "title is-4" }, [
                  _vm._v("Loading Report Preview"),
                ]),
              ]),
              _c("br"),
              _c("spin-loader", { attrs: { "is-large": "" } }),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }